import { getClient } from 'services/ApiService/apiManager';
import { config } from 'config/config';

const AuthClient = getClient({
  baseURL: config.endpoints.auth,
}, {
  useAuthHeaders: false,
  checkForDataErrors: false,
});

class AuthService {

  async getToken(user, password) {
    const formData = new FormData();

    formData.append('grant_type', 'password');
    formData.append('scope', 'webclient');
    formData.append('username', user);
    formData.append('password', password);

    return await AuthClient.post('token', formData, {
      auth: {
        username: process.env.REACT_APP_API_USER,
        password: process.env.REACT_APP_API_PASS,
      },
    });
  }

  async refreshToken(refreshToken) {
    const formData = new FormData();

    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refreshToken);

    return await AuthClient.post('token', formData, {
      auth: {
        username: process.env.REACT_APP_API_USER,
        password: process.env.REACT_APP_API_PASS,
      },
    });
  }
}

export default AuthService;

const colors = {
  color_white: '#ffffff',
  color_blue_0: '#c8e8f0',
  color_green_0: '#caedcb',
  color_green_1: '#178800',
  color_green_2: '#1d8b07',
  color_green_3: '#daedd6',
  color_grey_0: '#f3f3f3',
  color_grey_1: '#F5F5F5',
  color_grey_2: '#EFEFEF',
  color_grey_3: '#979797',
  color_grey_4: '#B2B2B2',
  color_grey_5: '#cacaca',
  color_grey_dark: '#707070',
  color_grey_dark_1: '#231f20',
  color_grey_dark_2: '#161617',
  color_white_transparent: 'rgba(255, 255, 255, 0.5)',
  color_black_transparent: 'rgba(0, 0, 0, 0.5)',
  color_darkest: '#000000',
  color_dark: '#231f20',
  color_yellow_0: '#fdf8cf',
  color_yellow_1: '#f7e626',
  color_yellow_2: '#efcf05',
  color_yellow_3: '#ffeab9',
  color_red: '#c52b2b',
  color_light_red_1: '#ffe3e3',
  color_red_1: '#ff3a3a',
  color_red_error: '#ffc7c7',
  color_red_error2: '#f3d5d5',
  color_focus: 'rgba(0, 150, 255, 1)',
  color_purple: '#dad7ff',
};

export const Theme = {
  ...colors,
  ease_out_quad: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  ease_in_quad: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
  footer: {
    link_color: colors.color_white,
    hover_color: colors.color_white,
  },
  font_family: "'Source Sans Pro', sans-serif;",
  font_weight_light: 300,
  font_weight_regular: 400,
  font_weight_semi_bold: 600,
  font_weight_bold: 700,
};

export {colors as Colors};

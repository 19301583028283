import React, { Component } from 'react';
import styled from 'styled-components/macro';
import media from 'utils/media';
import { rhythm } from 'utils/helpers';
import { connect } from 'react-redux';
import { createOrder, resetNewOrder, applyNewOrderToSelected } from 'store/actions/orders';
import { Link, Redirect } from 'react-router-dom';
import { ROUTES } from 'utils/constants/constants';
import { ProductOrder } from 'utils/constants/lookups';

import { Tier } from 'components/ui/Layout/Tier';
import Typography from 'components/ui/Typography/Typography';
import Container from 'components/ui/Layout/Container';
import NewOrderCard from './NewOrderCard';
import Box from 'components/ui/Layout/Box/Box';
import Icon from 'components/ui/Icons/Icon';
import ProductDesignImages from 'pages/ProductDesign/ProductDesignImages';

const LoginHeader = styled.div`
  padding-bottom: ${rhythm(3)};

  ${media.lg`
    padding-bottom: ${rhythm(7)};
  `}
`;

const Products = styled.div`
  ${media.md`
    display: flex;
    flex-direction: row;
    ${props => props.wrap ? 'flex-wrap: wrap;' : ''}
    margin: -${rhythm(2)} -${rhythm(2)};
  `}
`;

const NewOrderTier = styled(Tier)`
  background-color: ${props => props.theme.color_grey_1};
`;

const BackLink = styled(Link)`
  align-items: center;
  display: inline-flex;
`;

export class NewOrder extends Component {
  constructor() {
    super();

    this.state = {
      newOrderPending: false,
      imagesCreated: false,
      maleImage: null,
      femaleImage: null,
      selectedProduct: null,
    };
  }

  componentWillUnmount() {
    this.props.resetNewOrder();
  }

  handleNewOrderClick(e, product) {
    e.preventDefault();

    this.setState({
      selectedProduct: product,
    }, () => {
      this.props.applyNewOrderToSelected(this.state.selectedProduct, this.props.user.id, this.props.user.storeNo).finally(() => {
        this.setState({
          newOrderPending: true,
        });
      });
    });
  }

  handleImagesGenerated(male, female) {
    this.setState({
      imagesCreated: true,
      maleImage: male,
      femaleImage: female,
    });

    this.props.createOrder(this.props.order, this.props.user.id, this.props.user.storeNo, male, female);
  }

  renderNewOrder(product, idx) {
    if (!product) return;

    return (
      <NewOrderCard
        key={idx}
        handleNewOrderClick={(e, product) => this.handleNewOrderClick(e, product)}
        product={product}
      />
    );
  }

  render() {
    const { savedOrder, saveOrderSuccess, products, order } = this.props;
    const { newOrderPending } = this.state;

    if (!products || Object.keys(products).length < 1) return null;

    if (saveOrderSuccess) {
      return <Redirect push to={`${ROUTES.ORDER}/${savedOrder.groupId}`} />;
    }

    return (
      <>
        <NewOrderTier height='full'>
          <Container>
            <LoginHeader>
              <BackLink to={ROUTES.ORDERS}>
                <Icon size={24} name="chevron"></Icon>
                <Typography variant="meta1" weight="bold">
                  BACK
                </Typography>
              </BackLink>
              <Box classes="top2 bottom3">
                <Typography variant="h1">
                  Select a Product
                </Typography>
              </Box>
              <Products wrap={products && Object.keys(products).length > 3}>
                {products && ProductOrder.map((key, i) =>
                  this.renderNewOrder(products[key], i)
                )}
              </Products>
            </LoginHeader>
          </Container>
        </NewOrderTier>
        { newOrderPending && order &&
          <ProductDesignImages onImagesCreated={(male, female) => this.handleImagesGenerated(male, female)}></ProductDesignImages>
        }
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createOrder: (order, userId, storeId, maleImage, femaleImage) => dispatch(createOrder(order, userId, storeId, maleImage, femaleImage)),
  resetNewOrder: () => dispatch(resetNewOrder()),
  applyNewOrderToSelected: (product, userId, storeId) => dispatch(applyNewOrderToSelected(product, userId, storeId)),
});

const mapStateToProps = state => {
  return {
    products: state.styles.jackets,
    savedOrder: state.newOrder.savedOrder,
    saveOrderSuccess: state.newOrder.saveOrderSuccess,
    user: state.account.user,
    order: state.selectedOrder,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrder);

import { STY901 } from 'utils/constants/jackets/STY901';
import { STY902 } from 'utils/constants/jackets/STY902';
import { STY903 } from 'utils/constants/jackets/STY903';
import { STY801 } from 'utils/constants/jackets/STY801';

export const GetString = (lookup, object) => {
  if (lookup in object) {
    return object[lookup];
  }

  return lookup;
};

export const oldStyles = ['STY001', 'STY002', 'STY003', 'STY004', 'STY005', 'STY006', 'STY007', 'STY008', 'STY009', 'STY601', 'STY602', 'STY603', 'STY604'];

export const SizeTranslations = {
  '2X-Small': 'XXS',
  'X-Small': 'XS',
  'X-Large': 'XL',
  '2X-Large': '2XL',
  '3X-Large': '3XL',
  '4X-Large': '4XL',
  '5X-Large': '5XL',
  '5XL-Large': '5XL',
  'Sleeve Length -1"': 'Short (-1”)',
  'Regular Sleeve Length': 'Regular',
  "-1'' Sleeve Length": 'Short (-1”)',
  "+1'' Sleeve Length": 'Long (+1”)',
  'Sleeve Length  +1"': 'Long (+1”)',
  'Regular Fit': 'Regular',
  'Slim Fit': 'Slim',
  'Full Fit': 'Full',
};

export const OptionTranslations = {
  'Duck Down-700 fill power': 'Duck Down',
  'Goose Down-800 fill power': 'Goose Down',
  'no image': 'No Logo',
};

export const ReviewOptionTranslations = {
  ...OptionTranslations,
  'Pocket': 'Add Pocket',
  'Hood': 'Add Hood',
  'no image': 'No Logo',
  ' ': 'Add Logo',
  '': 'Add Logo',
};

export const ProductOrder = ['STY901', 'STY903', 'STY902', 'STY801'];

// These are short term fixes for unavailable data points.
export const ProductDescriptions = {
  'STY901': 'Extremely versatile, get ready to experience the perfect warmth for just about everything. Our Down Jacket delivers lightweight, compressible warmth for cool-weather adventures.',
  'STY902': 'Super warm, lightweight and compressible, our Down Vest delivers the utmost in versatility and convenience.  Layer up in winter or use as outerwear in the Fall, no matter the season, this vest never be left behind.',
  'STY903': 'Deliver cold-weather warmth, quality and performance like never before with our Puffy Down Jacket.  Insulated with warm 650 or 750 Fill-Power down, and coated with a DWR for water and wind resistance, this jacket can take on what mother nature will deliver.',
  'STY801': 'Experience true waterproof/breathable comfort with our premium 2.5 Layer Rain Jacket.  Extremely soft to the touch, ultra-lightweight and packable, this jacket will quickly become your clients go-to, never-leave-home without, rain jacket.',
};

//These translate the configurable option namespace
export const StyleNames = {
  'I002': 'Down Type',
  'I003': 'Hood/Collar',
  'I005': 'Chest Pocket',
  'I009': 'Body Color', // Main Body Bottom Color
  'I008': 'Shoulder Color', // Main Body Top Color
  'I004': 'Hood/Collar Color',
  'I007': 'Right Sleeve Color',
  'I006': 'Left Sleeve Color',
  'I010': 'Side Panel Color',
  // 'I025': 'Side Panel Color',
  'I016': 'Main Zipper Color',
  'I017': 'Pocket Zipper Color',
  'I011': 'Liner Color',
  'F002': 'Hood Shape',
  'F003': 'Chest Pocket(s)',
  'C001': 'Hood Color',
  'C007': 'Chest Color',
  'C009': 'Body Color',
  'C011': 'Lower Body Color',
  'C017': 'Back Color',
  'C013': 'Upper Sleeve Color',
  'C014': 'Lower Sleeve Color',
  'C018': 'Main Zipper Color',
  'C020': 'Chest Pocket Zipper Color',
  'C022': 'Lower Pocket Zipper Color',
  'L001': 'Logo: Left Chest',
  'L010': 'Logo: Inside',
  'L007': 'Logo: Left Sleeve',
  'L009': 'Logo: Right Shoulder',
  'L011': 'Logo: Back',
  'L012': 'Logo: Lower Back',
  'L006': 'Logo: Hood',
};

// I012 - Shape is a sizing option
// I013 - Left Sleeve Length is a size
// I014 - Right Sleeve Length is a size
// I015 - Jacket Size is a size

// Going to keep this seperate to make updates in code easier.
export const VisibleColorOrder = [
  '17',
  '42',
  '8',
  '41',
  '6',
  '1',
  '13',
  '9',
  '18',
  '10',
  '2',
  '11',
  '33',
  '39',
  '4',
  '34',
  '14',
  '12',
  '3',
  '5',
  '31',
  '35',
  '7',
  '40',
  '16',
  '15',
  '32',
];

// COLORS NOT IN USE
// Coconut
// Milk	F0EDE5
// Ginger Root	BFA58A
// Antartica	C6C5C6
// Orchid Hush	CEC3D2
// Blue Glow	B2D4DD
// Poseidon	123955
// Scarab	23312D
// Jet Black	2D2C2F
// Pickled Beet	4D233D
// Ember Glow	EA6759
// Caberet	CB3373
// Blue Atoll	00B1D2
// Mettalic Navy	EDDD59
// Metallic Silver	5A7247
// Camouflage	00585E

// I012 - Shape is a sizing option
// I013 - Left Sleeve Length is a size
// I014 - Right Sleeve Length is a size
// I015 - Jacket Size is a size
// G001 - Gender for gender nuetral jackets

export const sizeOptions = {
  'gender': 'G001',
  'sleeve': 'I013',
  'size': 'I015',
  'fit': 'I012',
};

export const featureToAngleMapping = {
  default: {
    'I007': 0,
    'I006': 0,
    'I011': 4,
    'I016': 0,
    'I017': 0,
    'L001': 5,
    'L010': 7,
    'L007': 6,
    'L011': 8,
  },
  vest: {
    'I007': 0,
    'I006': 0,
    'I011': 2,
    'I016': 0,
    'I017': 0,
    'L001': 3,
    'L010': 4,
    'L011': 5,
  },
  rain_jacket: {
    'F002': 0,
    'F003': 0,
    'C001': 0,
    'C007': 0,
    'C009': 0,
    'C011': 0,
    'C013': 0,
    'C014': 0,
    'C017': 4,
    'C018': 0,
    'C020': 0,
    'C022': 0,
    'L001': 8,
    'L010': 6,
    'L007': 9,
    'L009': 7,
    'L012': 10,
  },
};

export const customizerConfiguration = {
  'STY901': STY901,
  'STY902': STY902,
  'STY903': STY903,
  'STY801': STY801,
};

export const storeByEmailOverrides = {
  'todd@mondorobot.com': 'STR998',
};

export const anglesRequiringLogo = '';

export const logoPlacementConfig = {
  "L001_1_m_jacket_collar_nopocket": {
    "Jacket Angle": "L001_1_m_jacket_collar_nopocket",
    "Angle": "-3.75",
    "X Scale": "50.79",
    "Y Scale": "50.79",
    "X Coordinate": "800.14",
    "Y Coordinate": "397.07",
  },
  "L001_1_m_jacket_collar_pocket": {
    "Jacket Angle": "L001_1_m_jacket_collar_pocket",
    "Angle": "-2.08",
    "X Scale": "50.79",
    "Y Scale": "50.79",
    "X Coordinate": "823.02",
    "Y Coordinate": "395.50",
  },
  "L007_2_m_jacket_collar_nopocket": {
    "Jacket Angle": "L007_2_m_jacket_collar_nopocket",
    "Angle": "2.79",
    "X Scale": "54.61",
    "Y Scale": "54.61",
    "X Coordinate": "634.00",
    "Y Coordinate": "402.32",
  },
  "L007_2_m_jacket_collar_pocket": {
    "Jacket Angle": "L007_2_m_jacket_collar_pocket",
    "Angle": "2.79",
    "X Scale": "54.61",
    "Y Scale": "54.61",
    "X Coordinate": "634.00",
    "Y Coordinate": "402.32",
  },
  "L011_3_m_jacket_collar": {
    "Jacket Angle": "L011_3_m_jacket_collar",
    "Angle": "0",
    "X Scale": "41.79",
    "Y Scale": "41.79",
    "X Coordinate": "648.62",
    "Y Coordinate": "223.13",
  },
  "L001_5_m_jacket_collar_nopocket": {
    "Jacket Angle": "L001_5_m_jacket_collar_nopocket",
    "Angle": "-3.58",
    "X Scale": "53.87",
    "Y Scale": "53.87",
    "X Coordinate": "839.54",
    "Y Coordinate": "377.55",
  },
  "L001_5_m_jacket_collar_pocket": {
    "Jacket Angle": "L001_5_m_jacket_collar_pocket",
    "Angle": "-3.15",
    "X Scale": "53.87",
    "Y Scale": "53.87",
    "X Coordinate": "862.70",
    "Y Coordinate": "375.61",
  },
  "L001_6_m_jacket_collar_nopocket": {
    "Jacket Angle": "L001_6_m_jacket_collar_nopocket",
    "Angle": "-3.49",
    "X Scale": "173.31",
    "Y Scale": "173.31",
    "X Coordinate": "551.38",
    "Y Coordinate": "627.28",
  },
  "L001_6_m_jacket_collar_pocket": {
    "Jacket Angle": "L001_6_m_jacket_collar_pocket",
    "Angle": "-1.62",
    "X Scale": "173.31",
    "Y Scale": "173.31",
    "X Coordinate": "662.28",
    "Y Coordinate": "618.66",
  },
  "L007_7_m_jacket_collar_nopocket": {
    "Jacket Angle": "L007_7_m_jacket_collar_nopocket",
    "Angle": "1.27",
    "X Scale": "123.07",
    "Y Scale": "123.07",
    "X Coordinate": "633.70",
    "Y Coordinate": "531.29",
  },
  "L007_7_m_jacket_collar_pocket": {
    "Jacket Angle": "L007_7_m_jacket_collar_pocket",
    "Angle": "1.68",
    "X Scale": "123.07",
    "Y Scale": "123.07",
    "X Coordinate": "619.80",
    "Y Coordinate": "533.62",
  },
  "L010_8_m_jacket_collar": {
    "Jacket Angle": "L010_8_m_jacket_collar",
    "Angle": "-1.1",
    "X Scale": "133.72",
    "Y Scale": "133.72",
    "X Coordinate": "651.91",
    "Y Coordinate": "474.30",
  },
  "L011_9_m_jacket_collar": {
    "Jacket Angle": "L011_9_m_jacket_collar",
    "Angle": "0",
    "X Scale": "109.36",
    "Y Scale": "109.36",
    "X Coordinate": "647.05",
    "Y Coordinate": "275.42",
  },
  "L001_1_m_jacket_hood_nopocket": {
    "Jacket Angle": "L001_1_m_jacket_hood_nopocket",
    "Angle": "-1.57",
    "X Scale": "38.50",
    "Y Scale": "38.50",
    "X Coordinate": "761.39",
    "Y Coordinate": "561.42",
  },
  "L001_1_m_jacket_hood_pocket": {
    "Jacket Angle": "L001_1_m_jacket_hood_pocket",
    "Angle": "-0.43",
    "X Scale": "37.56",
    "Y Scale": "37.56",
    "X Coordinate": "796.54",
    "Y Coordinate": "571.15",
  },
  "L007_2_m_jacket_hood_nopocket": {
    "Jacket Angle": "L007_2_m_jacket_hood_nopocket",
    "Angle": "3.36",
    "X Scale": "42.49",
    "Y Scale": "42.49",
    "X Coordinate": "673.35",
    "Y Coordinate": "574.55",
  },
  "L007_2_m_jacket_hood_pocket": {
    "Jacket Angle": "L007_2_m_jacket_hood_pocket",
    "Angle": "3.36",
    "X Scale": "42.49",
    "Y Scale": "42.49",
    "X Coordinate": "673.35",
    "Y Coordinate": "574.55",
  },
  "L011_3_m_jacket_hood": {
    "Jacket Angle": "L011_3_m_jacket_hood",
    "Angle": "0",
    "X Scale": "41.15",
    "Y Scale": "41.15",
    "X Coordinate": "653.44",
    "Y Coordinate": "403.11",
  },
  "L001_5_m_jacket_hood_nopocket": {
    "Jacket Angle": "L001_5_m_jacket_hood_nopocket",
    "Angle": "-1.92",
    "X Scale": "39.36",
    "Y Scale": "39.36",
    "X Coordinate": "798.70",
    "Y Coordinate": "547.59",
  },
  "L001_5_m_jacket_hood_pocket": {
    "Jacket Angle": "L001_5_m_jacket_hood_pocket",
    "Angle": "-0.47",
    "X Scale": "39.36",
    "Y Scale": "39.36",
    "X Coordinate": "820.28",
    "Y Coordinate": "546.86",
  },
  "L001_6_m_jacket_hood_nopocket": {
    "Jacket Angle": "L001_6_m_jacket_hood_nopocket",
    "Angle": "-2.19",
    "X Scale": "131.52",
    "Y Scale": "131.52",
    "X Coordinate": "550.43",
    "Y Coordinate": "610.84",
  },
  "L001_6_m_jacket_hood_pocket": {
    "Jacket Angle": "L001_6_m_jacket_hood_pocket",
    "Angle": "0",
    "X Scale": "131.52",
    "Y Scale": "131.52",
    "X Coordinate": "653.00",
    "Y Coordinate": "609.50",
  },
  "L007_7_m_jacket_hood_nopocket": {
    "Jacket Angle": "L007_7_m_jacket_hood_nopocket",
    "Angle": "4.49",
    "X Scale": "103.51",
    "Y Scale": "103.51",
    "X Coordinate": "643.65",
    "Y Coordinate": "520.56",
  },
  "L007_7_m_jacket_hood_pocket": {
    "Jacket Angle": "L007_7_m_jacket_hood_pocket",
    "Angle": "4.49",
    "X Scale": "103.51",
    "Y Scale": "103.51",
    "X Coordinate": "643.65",
    "Y Coordinate": "520.56",
  },
  "L010_8_m_jacket_hood": {
    "Jacket Angle": "L010_8_m_jacket_hood",
    "Angle": "0.74",
    "X Scale": "140.58",
    "Y Scale": "140.58",
    "X Coordinate": "679.97",
    "Y Coordinate": "239.41",
  },
  "L011_9_m_jacket_hood": {
    "Jacket Angle": "L011_9_m_jacket_hood",
    "Angle": "0",
    "X Scale": "121.60",
    "Y Scale": "121.60",
    "X Coordinate": "647.35",
    "Y Coordinate": "238.09",
  },
  "L001_1_m_stuffed_jacket_collar_nopocket": {
    "Jacket Angle": "L001_1_m_stuffed_jacket_collar_nopocket",
    "Angle": "-2.67",
    "X Scale": "43.34",
    "Y Scale": "43.34",
    "X Coordinate": "775.22",
    "Y Coordinate": "358.08",
  },
  "L001_1_m_stuffed_jacket_collar_pocket": {
    "Jacket Angle": "L001_1_m_stuffed_jacket_collar_pocket",
    "Angle": "-2.09",
    "X Scale": "44.14",
    "Y Scale": "44.14",
    "X Coordinate": "800.60",
    "Y Coordinate": "357.41",
  },
  "L007_2_m_stuffed_jacket_collar_nopocket": {
    "Jacket Angle": "L007_2_m_stuffed_jacket_collar_nopocket",
    "Angle": "13.57",
    "X Scale": "55.98",
    "Y Scale": "55.98",
    "X Coordinate": "682.38",
    "Y Coordinate": "454.14",
  },
  "L007_2_m_stuffed_jacket_collar_pocket": {
    "Jacket Angle": "L007_2_m_stuffed_jacket_collar_pocket",
    "Angle": "13.57",
    "X Scale": "55.98",
    "Y Scale": "55.98",
    "X Coordinate": "682.38",
    "Y Coordinate": "454.14",
  },
  "L011_3_m_stuffed_jacket_collar": {
    "Jacket Angle": "L011_3_m_stuffed_jacket_collar",
    "Angle": "0",
    "X Scale": "44.93",
    "Y Scale": "44.93",
    "X Coordinate": "650.00",
    "Y Coordinate": "244.59",
  },
  "L001_5_m_stuffed_jacket_collar_nopocket": {
    "Jacket Angle": "L001_5_m_stuffed_jacket_collar_nopocket",
    "Angle": "1.05",
    "X Scale": "47.94",
    "Y Scale": "47.94",
    "X Coordinate": "815.97",
    "Y Coordinate": "355.70",
  },
  "L001_5_m_stuffed_jacket_collar_pocket": {
    "Jacket Angle": "L001_5_m_stuffed_jacket_collar_pocket",
    "Angle": "0.94",
    "X Scale": "47.94",
    "Y Scale": "47.94",
    "X Coordinate": "823.07",
    "Y Coordinate": "356.79",
  },
  "L001_6_m_stuffed_jacket_collar_nopocket": {
    "Jacket Angle": "L001_6_m_stuffed_jacket_collar_nopocket",
    "Angle": "-1.5",
    "X Scale": "139.99",
    "Y Scale": "139.99",
    "X Coordinate": "544.22",
    "Y Coordinate": "617.35",
  },
  "L001_6_m_stuffed_jacket_collar_pocket": {
    "Jacket Angle": "L001_6_m_stuffed_jacket_collar_pocket",
    "Angle": "-2.05",
    "X Scale": "139.99",
    "Y Scale": "139.99",
    "X Coordinate": "606.70",
    "Y Coordinate": "610.87",
  },
  "L007_7_m_stuffed_jacket_collar_nopocket": {
    "Jacket Angle": "L007_7_m_stuffed_jacket_collar_nopocket",
    "Angle": "12.48",
    "X Scale": "115.53",
    "Y Scale": "115.53",
    "X Coordinate": "679.51",
    "Y Coordinate": "632.01",
  },
  "L007_7_m_stuffed_jacket_collar_pocket": {
    "Jacket Angle": "L007_7_m_stuffed_jacket_collar_pocket",
    "Angle": "12.48",
    "X Scale": "115.53",
    "Y Scale": "115.53",
    "X Coordinate": "679.51",
    "Y Coordinate": "632.01",
  },
  "L010_8_m_stuffed_jacket_collar": {
    "Jacket Angle": "L010_8_m_stuffed_jacket_collar",
    "Angle": "-0.18",
    "X Scale": "105.63",
    "Y Scale": "105.63",
    "X Coordinate": "662.14",
    "Y Coordinate": "542.79",
  },
  "L011_9_m_stuffed_jacket_collar": {
    "Jacket Angle": "L011_9_m_stuffed_jacket_collar",
    "Angle": "0",
    "X Scale": "117.47",
    "Y Scale": "117.47",
    "X Coordinate": "655.03",
    "Y Coordinate": "347.72",
  },
  "L001_1_m_stuffed_jacket_hood_nopocket": {
    "Jacket Angle": "L001_1_m_stuffed_jacket_hood_nopocket",
    "Angle": "-1.06",
    "X Scale": "37.80",
    "Y Scale": "37.80",
    "X Coordinate": "729.49",
    "Y Coordinate": "555.20",
  },
  "L001_1_m_stuffed_jacket_hood_pocket": {
    "Jacket Angle": "L001_1_m_stuffed_jacket_hood_pocket",
    "Angle": "-0.83",
    "X Scale": "37.80",
    "Y Scale": "37.80",
    "X Coordinate": "776.46",
    "Y Coordinate": "554.26",
  },
  "L007_2_m_stuffed_jacket_hood_nopocket": {
    "Jacket Angle": "L007_2_m_stuffed_jacket_hood_nopocket",
    "Angle": "9.85",
    "X Scale": "43.22",
    "Y Scale": "43.22",
    "X Coordinate": "646.82",
    "Y Coordinate": "588.88",
  },
  "L007_2_m_stuffed_jacket_hood_pocket": {
    "Jacket Angle": "L007_2_m_stuffed_jacket_hood_pocket",
    "Angle": "9.85",
    "X Scale": "43.22",
    "Y Scale": "43.22",
    "X Coordinate": "646.82",
    "Y Coordinate": "588.88",
  },
  "L011_3_m_stuffed_jacket_hood": {
    "Jacket Angle": "L011_3_m_stuffed_jacket_hood",
    "Angle": "-1.38",
    "X Scale": "38.19",
    "Y Scale": "38.19",
    "X Coordinate": "633.78",
    "Y Coordinate": "446.16",
  },
  "L001_5_m_stuffed_jacket_hood_nopocket": {
    "Jacket Angle": "L001_5_m_stuffed_jacket_hood_nopocket",
    "Angle": "0",
    "X Scale": "38.78",
    "Y Scale": "38.78",
    "X Coordinate": "758.74",
    "Y Coordinate": "544.89",
  },
  "L001_5_m_stuffed_jacket_hood_pocket": {
    "Jacket Angle": "L001_5_m_stuffed_jacket_hood_pocket",
    "Angle": "0.67",
    "X Scale": "38.78",
    "Y Scale": "38.78",
    "X Coordinate": "793.13",
    "Y Coordinate": "544.85",
  },
  "L001_6_m_stuffed_jacket_hood_nopocket": {
    "Jacket Angle": "L001_6_m_stuffed_jacket_hood_nopocket",
    "Angle": "-2.14",
    "X Scale": "133.24",
    "Y Scale": "133.24",
    "X Coordinate": "541.02",
    "Y Coordinate": "631.59",
  },
  "L001_6_m_stuffed_jacket_hood_pocket": {
    "Jacket Angle": "L001_6_m_stuffed_jacket_hood_pocket",
    "Angle": "-2.35",
    "X Scale": "133.24",
    "Y Scale": "133.24",
    "X Coordinate": "647.17",
    "Y Coordinate": "636.51",
  },
  "L007_7_m_stuffed_jacket_hood_nopocket": {
    "Jacket Angle": "L007_7_m_stuffed_jacket_hood_nopocket",
    "Angle": "10.1",
    "X Scale": "103.33",
    "Y Scale": "103.33",
    "X Coordinate": "660.53",
    "Y Coordinate": "542.83",
  },
  "L007_7_m_stuffed_jacket_hood_pocket": {
    "Jacket Angle": "L007_7_m_stuffed_jacket_hood_pocket",
    "Angle": "10.1",
    "X Scale": "103.33",
    "Y Scale": "103.33",
    "X Coordinate": "660.53",
    "Y Coordinate": "542.83",
  },
  "L010_8_m_stuffed_jacket_hood": {
    "Jacket Angle": "L010_8_m_stuffed_jacket_hood",
    "Angle": "-0.45",
    "X Scale": "102.89",
    "Y Scale": "102.89",
    "X Coordinate": "662.55",
    "Y Coordinate": "376.44",
  },
  "L011_9_m_stuffed_jacket_hood": {
    "Jacket Angle": "L011_9_m_stuffed_jacket_hood",
    "Angle": "-2.13",
    "X Scale": "116.17",
    "Y Scale": "116.17",
    "X Coordinate": "649.10",
    "Y Coordinate": "378.15",
  },
  "L001_1_m_vest_collar_nopocket": {
    "Jacket Angle": "L001_1_m_vest_collar_nopocket",
    "Angle": "-0.66",
    "X Scale": "51.03",
    "Y Scale": "51.03",
    "X Coordinate": "786.39",
    "Y Coordinate": "416.06",
  },
  "L001_1_m_vest_collar_pocket": {
    "Jacket Angle": "L001_1_m_vest_collar_pocket",
    "Angle": "-0.66",
    "X Scale": "51.03",
    "Y Scale": "51.03",
    "X Coordinate": "815.55",
    "Y Coordinate": "416.06",
  },
  "L011_3_m_vest_collar": {
    "Jacket Angle": "L011_3_m_vest_collar",
    "Angle": "0",
    "X Scale": "44.71",
    "Y Scale": "44.71",
    "X Coordinate": "653.52",
    "Y Coordinate": "215.05",
  },
  "L001_5_m_vest_collar_nopocket": {
    "Jacket Angle": "L001_5_m_vest_collar_nopocket",
    "Angle": "-1.39",
    "X Scale": "51.74",
    "Y Scale": "51.74",
    "X Coordinate": "825.13",
    "Y Coordinate": "392.08",
  },
  "L001_5_m_vest_collar_pocket": {
    "Jacket Angle": "L001_5_m_vest_collar_pocket",
    "Angle": "-0.68",
    "X Scale": "43.09",
    "Y Scale": "43.09",
    "X Coordinate": "854.77",
    "Y Coordinate": "391.70",
  },
  "L001_6_m_vest_collar_nopocket": {
    "Jacket Angle": "L001_6_m_vest_collar_nopocket",
    "Angle": "0",
    "X Scale": "132.42",
    "Y Scale": "132.42",
    "X Coordinate": "612.63",
    "Y Coordinate": "591.70",
  },
  "L001_6_m_vest_collar_pocket": {
    "Jacket Angle": "L001_6_m_vest_collar_pocket",
    "Angle": "0",
    "X Scale": "132.42",
    "Y Scale": "132.42",
    "X Coordinate": "696.00",
    "Y Coordinate": "587.94",
  },
  "L010_8_m_vest_collar": {
    "Jacket Angle": "L010_8_m_vest_collar",
    "Angle": "-1.57",
    "X Scale": "134.71",
    "Y Scale": "134.71",
    "X Coordinate": "651.80",
    "Y Coordinate": "475.72",
  },
  "L011_9_m_vest_collar": {
    "Jacket Angle": "L011_9_m_vest_collar",
    "Angle": "0",
    "X Scale": "109.36",
    "Y Scale": "109.36",
    "X Coordinate": "647.05",
    "Y Coordinate": "275.42",
  },
  "L001_1_m_vest_hood_nopocket": {
    "Jacket Angle": "L001_1_m_vest_hood_nopocket",
    "Angle": "-2.18",
    "X Scale": "32.34",
    "Y Scale": "32.34",
    "X Coordinate": "755.20",
    "Y Coordinate": "577.07",
  },
  "L001_1_m_vest_hood_pocket": {
    "Jacket Angle": "L001_1_m_vest_hood_pocket",
    "Angle": "-1.31",
    "X Scale": "32.34",
    "Y Scale": "32.34",
    "X Coordinate": "777.93",
    "Y Coordinate": "575.65",
  },
  "L011_3_m_vest_hood": {
    "Jacket Angle": "L011_3_m_vest_hood",
    "Angle": "0.12",
    "X Scale": "34.18",
    "Y Scale": "34.18",
    "X Coordinate": "649.98",
    "Y Coordinate": "392.59",
  },
  "L001_5_m_vest_hood_nopocket": {
    "Jacket Angle": "L001_5_m_vest_hood_nopocket",
    "Angle": "-4.19",
    "X Scale": "43.58",
    "Y Scale": "43.58",
    "X Coordinate": "765.78",
    "Y Coordinate": "564.10",
  },
  "L001_5_m_vest_hood_pocket": {
    "Jacket Angle": "L001_5_m_vest_hood_pocket",
    "Angle": "-4.2",
    "X Scale": "40.24",
    "Y Scale": "40.24",
    "X Coordinate": "796.00",
    "Y Coordinate": "562.08",
  },
  "L001_6_m_vest_hood_nopocket": {
    "Jacket Angle": "L001_6_m_vest_hood_nopocket",
    "Angle": "-2.03",
    "X Scale": "127.94",
    "Y Scale": "127.94",
    "X Coordinate": "556.29",
    "Y Coordinate": "664.87",
  },
  "L001_6_m_vest_hood_pocket": {
    "Jacket Angle": "L001_6_m_vest_hood_pocket",
    "Angle": "-2.26",
    "X Scale": "138.06",
    "Y Scale": "138.06",
    "X Coordinate": "672.57",
    "Y Coordinate": "662.41",
  },
  "L010_8_m_vest_hood": {
    "Jacket Angle": "L010_8_m_vest_hood",
    "Angle": "0",
    "X Scale": "139.82",
    "Y Scale": "139.82",
    "X Coordinate": "683.06",
    "Y Coordinate": "239.99",
  },
  "L011_9_m_vest_hood": {
    "Jacket Angle": "L011_9_m_vest_hood",
    "Angle": "0",
    "X Scale": "121.60",
    "Y Scale": "121.60",
    "X Coordinate": "647.35",
    "Y Coordinate": "238.09",
  },
  "L001_1_w_jacket_collar_nopocket": {
    "Jacket Angle": "L001_1_w_jacket_collar_nopocket",
    "Angle": "-2.8",
    "X Scale": "46.10",
    "Y Scale": "46.10",
    "X Coordinate": "748.73",
    "Y Coordinate": "418.82",
  },
  "L001_1_w_jacket_collar_pocket": {
    "Jacket Angle": "L001_1_w_jacket_collar_pocket",
    "Angle": "-2.8",
    "X Scale": "46.10",
    "Y Scale": "46.10",
    "X Coordinate": "770.88",
    "Y Coordinate": "416.30",
  },
  "L007_2_w_jacket_collar_nopocket": {
    "Jacket Angle": "L007_2_w_jacket_collar_nopocket",
    "Angle": "1.84",
    "X Scale": "53.76",
    "Y Scale": "53.76",
    "X Coordinate": "708.33",
    "Y Coordinate": "453.68",
  },
  "L007_2_w_jacket_collar_pocket": {
    "Jacket Angle": "L007_2_w_jacket_collar_pocket",
    "Angle": "1.84",
    "X Scale": "53.76",
    "Y Scale": "53.76",
    "X Coordinate": "708.33",
    "Y Coordinate": "453.68",
  },
  "L011_3_w_jacket_collar": {
    "Jacket Angle": "L011_3_w_jacket_collar",
    "Angle": "-1.14",
    "X Scale": "46.54",
    "Y Scale": "46.54",
    "X Coordinate": "622.77",
    "Y Coordinate": "268.27",
  },
  "L001_5_w_jacket_collar_nopocket": {
    "Jacket Angle": "L001_5_w_jacket_collar_nopocket",
    "Angle": "-2.91",
    "X Scale": "52.53",
    "Y Scale": "58.79",
    "X Coordinate": "818.58",
    "Y Coordinate": "402.89",
  },
  "L001_5_w_jacket_collar_pocket": {
    "Jacket Angle": "L001_5_w_jacket_collar_pocket",
    "Angle": "-0.86",
    "X Scale": "47.70",
    "Y Scale": "53.68",
    "X Coordinate": "849.95",
    "Y Coordinate": "395.87",
  },
  "L001_6_w_jacket_collar_nopocket": {
    "Jacket Angle": "L001_6_w_jacket_collar_nopocket",
    "Angle": "-2.1",
    "X Scale": "173.58",
    "Y Scale": "173.58",
    "X Coordinate": "506.21",
    "Y Coordinate": "713.11",
  },
  "L001_6_w_jacket_collar_pocket": {
    "Jacket Angle": "L001_6_w_jacket_collar_pocket",
    "Angle": "-3.01",
    "X Scale": "164.69",
    "Y Scale": "164.69",
    "X Coordinate": "642.43",
    "Y Coordinate": "693.01",
  },
  "L007_7_w_jacket_collar_nopocket": {
    "Jacket Angle": "L007_7_w_jacket_collar_nopocket",
    "Angle": "1.53",
    "X Scale": "125.00",
    "Y Scale": "125.00",
    "X Coordinate": "785.85",
    "Y Coordinate": "615.55",
  },
  "L007_7_w_jacket_collar_pocket": {
    "Jacket Angle": "L007_7_w_jacket_collar_pocket",
    "Angle": "1.53",
    "X Scale": "115.55",
    "Y Scale": "115.55",
    "X Coordinate": "785.85",
    "Y Coordinate": "607.55",
  },
  "L010_8_w_jacket_collar": {
    "Jacket Angle": "L010_8_w_jacket_collar",
    "Angle": "0",
    "X Scale": "145.11",
    "Y Scale": "145.11",
    "X Coordinate": "651.82",
    "Y Coordinate": "590.74",
  },
  "L011_9_w_jacket_collar": {
    "Jacket Angle": "L011_9_w_jacket_collar",
    "Angle": "0",
    "X Scale": "140.57",
    "Y Scale": "140.57",
    "X Coordinate": "649.38",
    "Y Coordinate": "346.99",
  },
  "L001_1_w_jacket_hood_nopocket": {
    "Jacket Angle": "L001_1_w_jacket_hood_nopocket",
    "Angle": "-3.52",
    "X Scale": "39.97",
    "Y Scale": "39.97",
    "X Coordinate": "745.48",
    "Y Coordinate": "601.74",
  },
  "L001_1_w_jacket_hood_pocket": {
    "Jacket Angle": "L001_1_w_jacket_hood_pocket",
    "Angle": "-4.46",
    "X Scale": "37.45",
    "Y Scale": "37.45",
    "X Coordinate": "773.63",
    "Y Coordinate": "596.18",
  },
  "L007_2_w_jacket_hood_nopocket": {
    "Jacket Angle": "L007_2_w_jacket_hood_nopocket",
    "Angle": "0.54",
    "X Scale": "49.74",
    "Y Scale": "49.74",
    "X Coordinate": "677.86",
    "Y Coordinate": "594.25",
  },
  "L007_2_w_jacket_hood_pocket": {
    "Jacket Angle": "L007_2_w_jacket_hood_pocket",
    "Angle": "0.54",
    "X Scale": "49.74",
    "Y Scale": "49.74",
    "X Coordinate": "677.86",
    "Y Coordinate": "594.25",
  },
  "L011_3_w_jacket_hood": {
    "Jacket Angle": "L011_3_w_jacket_hood",
    "Angle": "0",
    "X Scale": "39.42",
    "Y Scale": "39.42",
    "X Coordinate": "642.12",
    "Y Coordinate": "426.09",
  },
  "L001_5_w_jacket_hood_nopocket": {
    "Jacket Angle": "L001_5_w_jacket_hood_nopocket",
    "Angle": "-3.93",
    "X Scale": "44.51",
    "Y Scale": "44.51",
    "X Coordinate": "765.58",
    "Y Coordinate": "575.24",
  },
  "L001_5_w_jacket_hood_pocket": {
    "Jacket Angle": "L001_5_w_jacket_hood_pocket",
    "Angle": "-3.72",
    "X Scale": "38.42",
    "Y Scale": "38.42",
    "X Coordinate": "800.04",
    "Y Coordinate": "571.97",
  },
  "L001_6_w_jacket_hood_nopocket": {
    "Jacket Angle": "L001_6_w_jacket_hood_nopocket",
    "Angle": "-3.67",
    "X Scale": "142.64",
    "Y Scale": "142.64",
    "X Coordinate": "544.58",
    "Y Coordinate": "677.86",
  },
  "L001_6_w_jacket_hood_pocket": {
    "Jacket Angle": "L001_6_w_jacket_hood_pocket",
    "Angle": "-3.55",
    "X Scale": "137.07",
    "Y Scale": "137.07",
    "X Coordinate": "660.62",
    "Y Coordinate": "661.00",
  },
  "L007_7_w_jacket_hood_nopocket": {
    "Jacket Angle": "L007_7_w_jacket_hood_nopocket",
    "Angle": "-0.67",
    "X Scale": "123.65",
    "Y Scale": "123.65",
    "X Coordinate": "652.53",
    "Y Coordinate": "524.35",
  },
  "L007_7_w_jacket_hood_pocket": {
    "Jacket Angle": "L007_7_w_jacket_hood_pocket",
    "Angle": "-0.67",
    "X Scale": "123.65",
    "Y Scale": "123.65",
    "X Coordinate": "652.53",
    "Y Coordinate": "524.35",
  },
  "L010_8_w_jacket_hood": {
    "Jacket Angle": "L010_8_w_jacket_hood",
    "Angle": "-1.57",
    "X Scale": "152.72",
    "Y Scale": "152.72",
    "X Coordinate": "654.24",
    "Y Coordinate": "421.72",
  },
  "L011_9_w_jacket_hood": {
    "Jacket Angle": "L011_9_w_jacket_hood",
    "Angle": "0",
    "X Scale": "133.59",
    "Y Scale": "133.59",
    "X Coordinate": "642.62",
    "Y Coordinate": "318.30",
  },
  "L001_1_w_stuffed_jacket_collar_nopocket": {
    "Jacket Angle": "L001_1_w_stuffed_jacket_collar_nopocket",
    "Angle": "-3.81",
    "X Scale": "52.22",
    "Y Scale": "52.22",
    "X Coordinate": "772.97",
    "Y Coordinate": "397.51",
  },
  "L001_1_w_stuffed_jacket_collar_pocket": {
    "Jacket Angle": "L001_1_w_stuffed_jacket_collar_pocket",
    "Angle": "-3.81",
    "X Scale": "52.22",
    "Y Scale": "52.22",
    "X Coordinate": "800.66",
    "Y Coordinate": "394.26",
  },
  "L007_2_w_stuffed_jacket_collar_nopocket": {
    "Jacket Angle": "L007_2_w_stuffed_jacket_collar_nopocket",
    "Angle": "8.25",
    "X Scale": "57.20",
    "Y Scale": "57.20",
    "X Coordinate": "679.09",
    "Y Coordinate": "436.01",
  },
  "L007_2_w_stuffed_jacket_collar_pocket": {
    "Jacket Angle": "L007_2_w_stuffed_jacket_collar_pocket",
    "Angle": "8.25",
    "X Scale": "57.20",
    "Y Scale": "57.20",
    "X Coordinate": "679.09",
    "Y Coordinate": "436.01",
  },
  "L011_3_w_stuffed_jacket_collar": {
    "Jacket Angle": "L011_3_w_stuffed_jacket_collar",
    "Angle": "-1.34",
    "X Scale": "48.53",
    "Y Scale": "48.53",
    "X Coordinate": "636.54",
    "Y Coordinate": "243.96",
  },
  "L001_5_w_stuffed_jacket_collar_nopocket": {
    "Jacket Angle": "L001_5_w_stuffed_jacket_collar_nopocket",
    "Angle": "-4.03",
    "X Scale": "53.20",
    "Y Scale": "53.20",
    "X Coordinate": "804.09",
    "Y Coordinate": "405.48",
  },
  "L001_5_w_stuffed_jacket_collar_pocket": {
    "Jacket Angle": "L001_5_w_stuffed_jacket_collar_pocket",
    "Angle": "-4.06",
    "X Scale": "54.05",
    "Y Scale": "54.05",
    "X Coordinate": "852.00",
    "Y Coordinate": "402.13",
  },
  "L001_6_w_stuffed_jacket_collar_nopocket": {
    "Jacket Angle": "L001_6_w_stuffed_jacket_collar_nopocket",
    "Angle": "-3.3",
    "X Scale": "162.61",
    "Y Scale": "162.61",
    "X Coordinate": "474.83",
    "Y Coordinate": "664.66",
  },
  "L001_6_w_stuffed_jacket_collar_pocket": {
    "Jacket Angle": "L001_6_w_stuffed_jacket_collar_pocket",
    "Angle": "-3.63",
    "X Scale": "160.95",
    "Y Scale": "160.95",
    "X Coordinate": "618.37",
    "Y Coordinate": "654.52",
  },
  "L007_7_w_stuffed_jacket_collar_nopocket": {
    "Jacket Angle": "L007_7_w_stuffed_jacket_collar_nopocket",
    "Angle": "7.29",
    "X Scale": "117.42",
    "Y Scale": "117.42",
    "X Coordinate": "724.79",
    "Y Coordinate": "534.64",
  },
  "L007_7_w_stuffed_jacket_collar_pocket": {
    "Jacket Angle": "L007_7_w_stuffed_jacket_collar_pocket",
    "Angle": "7.29",
    "X Scale": "117.42",
    "Y Scale": "117.42",
    "X Coordinate": "724.79",
    "Y Coordinate": "534.64",
  },
  "L010_8_w_stuffed_jacket_collar": {
    "Jacket Angle": "L010_8_w_stuffed_jacket_collar",
    "Angle": "-1.39",
    "X Scale": "106.96",
    "Y Scale": "106.96",
    "X Coordinate": "663.09",
    "Y Coordinate": "463.76",
  },
  "L011_9_w_stuffed_jacket_collar": {
    "Jacket Angle": "L011_9_w_stuffed_jacket_collar",
    "Angle": "-1.14",
    "X Scale": "118.68",
    "Y Scale": "118.68",
    "X Coordinate": "648.95",
    "Y Coordinate": "298.76",
  },
  "L001_1_w_stuffed_jacket_hood_nopocket": {
    "Jacket Angle": "L001_1_w_stuffed_jacket_hood_nopocket",
    "Angle": "-1.57",
    "X Scale": "42.75",
    "Y Scale": "42.75",
    "X Coordinate": "736.88",
    "Y Coordinate": "581.02",
  },
  "L001_1_w_stuffed_jacket_hood_pocket": {
    "Jacket Angle": "L001_1_w_stuffed_jacket_hood_pocket",
    "Angle": "-3.33",
    "X Scale": "41.39",
    "Y Scale": "41.39",
    "X Coordinate": "772.94",
    "Y Coordinate": "578.66",
  },
  "L007_2_w_stuffed_jacket_hood_nopocket": {
    "Jacket Angle": "L007_2_w_stuffed_jacket_hood_nopocket",
    "Angle": "6.7",
    "X Scale": "46.99",
    "Y Scale": "46.99",
    "X Coordinate": "669.23",
    "Y Coordinate": "606.94",
  },
  "L007_2_w_stuffed_jacket_hood_pocket": {
    "Jacket Angle": "L007_2_w_stuffed_jacket_hood_pocket",
    "Angle": "6.7",
    "X Scale": "46.99",
    "Y Scale": "46.99",
    "X Coordinate": "669.23",
    "Y Coordinate": "606.94",
  },
  "L011_3_w_stuffed_jacket_hood": {
    "Jacket Angle": "L011_3_w_stuffed_jacket_hood",
    "Angle": "0",
    "X Scale": "39.92",
    "Y Scale": "39.92",
    "X Coordinate": "647.83",
    "Y Coordinate": "433.27",
  },
  "L001_5_w_stuffed_jacket_hood_nopocket": {
    "Jacket Angle": "L001_5_w_stuffed_jacket_hood_nopocket",
    "Angle": "-7.06",
    "X Scale": "44.79",
    "Y Scale": "44.79",
    "X Coordinate": "756.47",
    "Y Coordinate": "572.85",
  },
  "L001_5_w_stuffed_jacket_hood_pocket": {
    "Jacket Angle": "L001_5_w_stuffed_jacket_hood_pocket",
    "Angle": "-8.29",
    "X Scale": "38.52",
    "Y Scale": "38.52",
    "X Coordinate": "785.42",
    "Y Coordinate": "567.64",
  },
  "L001_6_w_stuffed_jacket_hood_nopocket": {
    "Jacket Angle": "L001_6_w_stuffed_jacket_hood_nopocket",
    "Angle": "-1.77",
    "X Scale": "142.04",
    "Y Scale": "142.04",
    "X Coordinate": "549.13",
    "Y Coordinate": "704.05",
  },
  "L001_6_w_stuffed_jacket_hood_pocket": {
    "Jacket Angle": "L001_6_w_stuffed_jacket_hood_pocket",
    "Angle": "-3.72",
    "X Scale": "137.25",
    "Y Scale": "137.25",
    "X Coordinate": "633.30",
    "Y Coordinate": "700.43",
  },
  "L007_7_w_stuffed_jacket_hood_nopocket": {
    "Jacket Angle": "L007_7_w_stuffed_jacket_hood_nopocket",
    "Angle": "7.29",
    "X Scale": "113.30",
    "Y Scale": "113.30",
    "X Coordinate": "698.59",
    "Y Coordinate": "487.97",
  },
  "L007_7_w_stuffed_jacket_hood_pocket": {
    "Jacket Angle": "L007_7_w_stuffed_jacket_hood_pocket",
    "Angle": "7.29",
    "X Scale": "113.30",
    "Y Scale": "113.30",
    "X Coordinate": "698.59",
    "Y Coordinate": "487.97",
  },
  "L010_8_w_stuffed_jacket_hood": {
    "Jacket Angle": "L010_8_w_stuffed_jacket_hood",
    "Angle": "0",
    "X Scale": "115.14",
    "Y Scale": "115.14",
    "X Coordinate": "661.43",
    "Y Coordinate": "456.46",
  },
  "L011_9_w_stuffed_jacket_hood": {
    "Jacket Angle": "L011_9_w_stuffed_jacket_hood",
    "Angle": "0",
    "X Scale": "123.68",
    "Y Scale": "123.68",
    "X Coordinate": "640.07",
    "Y Coordinate": "351.44",
  },
  "L001_1_w_vest_collar_nopocket": {
    "Jacket Angle": "L001_1_w_vest_collar_nopocket",
    "Angle": "-1.12",
    "X Scale": "45.38",
    "Y Scale": "45.38",
    "X Coordinate": "757.33",
    "Y Coordinate": "451.80",
  },
  "L001_1_w_vest_collar_pocket": {
    "Jacket Angle": "L001_1_w_vest_collar_pocket",
    "Angle": "-1.12",
    "X Scale": "45.38",
    "Y Scale": "45.38",
    "X Coordinate": "784.33",
    "Y Coordinate": "452.80",
  },
  "L011_3_w_vest_collar": {
    "Jacket Angle": "L011_3_w_vest_collar",
    "Angle": "-0.45",
    "X Scale": "45.87",
    "Y Scale": "45.87",
    "X Coordinate": "642.50",
    "Y Coordinate": "275.00",
  },
  "L001_5_w_vest_collar_nopocket": {
    "Jacket Angle": "L001_5_w_vest_collar_nopocket",
    "Angle": "1",
    "X Scale": "47.56",
    "Y Scale": "51.81",
    "X Coordinate": "840.39",
    "Y Coordinate": "380.22",
  },
  "L001_5_w_vest_collar_pocket": {
    "Jacket Angle": "L001_5_w_vest_collar_pocket",
    "Angle": "1.23",
    "X Scale": "27.12",
    "Y Scale": "43.29",
    "X Coordinate": "865.30",
    "Y Coordinate": "377.99",
  },
  "L001_6_w_vest_collar_nopocket": {
    "Jacket Angle": "L001_6_w_vest_collar_nopocket",
    "Angle": "-0.26",
    "X Scale": "156.81",
    "Y Scale": "156.81",
    "X Coordinate": "614.57",
    "Y Coordinate": "725.72",
  },
  "L001_6_w_vest_collar_pocket": {
    "Jacket Angle": "L001_6_w_vest_collar_pocket",
    "Angle": "-1.43",
    "X Scale": "145.04",
    "Y Scale": "145.04",
    "X Coordinate": "694.91",
    "Y Coordinate": "704.25",
  },
  "L010_8_w_vest_collar": {
    "Jacket Angle": "L010_8_w_vest_collar",
    "Angle": "0",
    "X Scale": "145.11",
    "Y Scale": "145.11",
    "X Coordinate": "651.82",
    "Y Coordinate": "590.74",
  },
  "L011_9_w_vest_collar": {
    "Jacket Angle": "L011_9_w_vest_collar",
    "Angle": "0",
    "X Scale": "140.57",
    "Y Scale": "140.57",
    "X Coordinate": "649.38",
    "Y Coordinate": "346.99",
  },
  "L001_1_w_vest_hood_nopocket": {
    "Jacket Angle": "L001_1_w_vest_hood_nopocket",
    "Angle": "-1.51",
    "X Scale": "36.74",
    "Y Scale": "36.74",
    "X Coordinate": "719.35",
    "Y Coordinate": "602.38",
  },
  "L001_1_w_vest_hood_pocket": {
    "Jacket Angle": "L001_1_w_vest_hood_pocket",
    "Angle": "-3.05",
    "X Scale": "34.60",
    "Y Scale": "34.60",
    "X Coordinate": "752.52",
    "Y Coordinate": "600.90",
  },
  "L011_3_w_vest_hood": {
    "Jacket Angle": "L011_3_w_vest_hood",
    "Angle": "0",
    "X Scale": "32.18",
    "Y Scale": "32.55",
    "X Coordinate": "642.55",
    "Y Coordinate": "472.24",
  },
  "L001_5_w_vest_hood_nopocket": {
    "Jacket Angle": "L001_5_w_vest_hood_nopocket",
    "Angle": "-2.95",
    "X Scale": "30.68",
    "Y Scale": "43.68",
    "X Coordinate": "759.26",
    "Y Coordinate": "586.53",
  },
  "L001_5_w_vest_hood_pocket": {
    "Jacket Angle": "L001_5_w_vest_hood_pocket",
    "Angle": "-10.88",
    "X Scale": "23.21",
    "Y Scale": "42.05",
    "X Coordinate": "784.05",
    "Y Coordinate": "587.85",
  },
  "L001_6_w_vest_hood_nopocket": {
    "Jacket Angle": "L001_6_w_vest_hood_nopocket",
    "Angle": "-1.36",
    "X Scale": "145.69",
    "Y Scale": "145.69",
    "X Coordinate": "556.60",
    "Y Coordinate": "729.39",
  },
  "L001_6_w_vest_hood_pocket": {
    "Jacket Angle": "L001_6_w_vest_hood_pocket",
    "Angle": "-3.18",
    "X Scale": "141.60",
    "Y Scale": "141.60",
    "X Coordinate": "698.90",
    "Y Coordinate": "707.14",
  },
  "L010_8_w_vest_hood": {
    "Jacket Angle": "L010_8_w_vest_hood",
    "Angle": "0",
    "X Scale": "145.11",
    "Y Scale": "145.11",
    "X Coordinate": "651.82",
    "Y Coordinate": "418.26",
  },
  "L011_9_w_vest_hood": {
    "Jacket Angle": "L011_9_w_vest_hood",
    "Angle": "0",
    "X Scale": "133.59",
    "Y Scale": "133.59",
    "X Coordinate": "642.62",
    "Y Coordinate": "318.30",
  },
  "L001_1_m_rain_jacket_foldablehood_bothpockets": {
    "Jacket Angle": "L001_1_m_rain_jacket_foldablehood_bothpockets",
    "Angle": "2",
    "X Scale": "52.00",
    "Y Scale": "52.00",
    "X Coordinate": "800",
    "Y Coordinate": "436.00",
  },
  "L001_1_m_rain_jacket_foldablehood_leftpocket": {
    "Jacket Angle": "L001_1_m_rain_jacket_foldablehood_leftpocket",
    "Angle": "2",
    "X Scale": "52.00",
    "Y Scale": "52.00",
    "X Coordinate": "800",
    "Y Coordinate": "436.00",
  },
  "L001_1_m_rain_jacket_foldablehood_nopocket": {
    "Jacket Angle": "L001_1_m_rain_jacket_foldablehood_nopocket",
    "Angle": "1",
    "X Scale": "52.00",
    "Y Scale": "52.00",
    "X Coordinate": "768.00",
    "Y Coordinate": "426.00",
  },
  "L001_1_m_rain_jacket_foldablehood_rightpocket": {
    "Jacket Angle": "L001_1_m_rain_jacket_foldablehood_rightpocket",
    "Angle": "1",
    "X Scale": "52.00",
    "Y Scale": "52.00",
    "X Coordinate": "768.00",
    "Y Coordinate": "426.00",
  },
  "L001_2_m_rain_jacket_foldablehood_bothpockets": {
    "Jacket Angle": "L001_2_m_rain_jacket_foldablehood_bothpockets",
    "Angle": "2",
    "X Scale": "43.00",
    "Y Scale": "43.00",
    "X Coordinate": "777.00",
    "Y Coordinate": "572.00",
  },
  "L001_2_m_rain_jacket_foldablehood_leftpocket": {
    "Jacket Angle": "L001_2_m_rain_jacket_foldablehood_leftpocket",
    "Angle": "2",
    "X Scale": "43.00",
    "Y Scale": "43.00",
    "X Coordinate": "777.00",
    "Y Coordinate": "572.00",
  },
  "L001_2_m_rain_jacket_foldablehood_nopocket": {
    "Jacket Angle": "L001_2_m_rain_jacket_foldablehood_nopocket",
    "Angle": "1",
    "X Scale": "43.00",
    "Y Scale": "43.00",
    "X Coordinate": "756.00",
    "Y Coordinate": "572.00",
  },
  "L001_2_m_rain_jacket_foldablehood_rightpocket": {
    "Jacket Angle": "L001_2_m_rain_jacket_foldablehood_rightpocket",
    "Angle": "1",
    "X Scale": "43.00",
    "Y Scale": "43.00",
    "X Coordinate": "756.00",
    "Y Coordinate": "572.00",
  },
  "L007_3_m_rain_jacket_foldablehood_pocket": {
    "Jacket Angle": "L007_3_m_rain_jacket_foldablehood_pocket",
    "Angle": "0",
    "X Scale": "28.51",
    "Y Scale": "28.51",
    "X Coordinate": "678.00",
    "Y Coordinate": "532.00",
  },
  "L007_3_m_rain_jacket_foldablehood_nopocket": {
    "Jacket Angle": "L007_3_m_rain_jacket_foldablehood_nopocket",
    "Angle": "0",
    "X Scale": "28.51",
    "Y Scale": "28.51",
    "X Coordinate": "678.00",
    "Y Coordinate": "532.00",
  },
  "L009_5_m_rain_jacket_foldablehood": {
    "Jacket Angle": "L009_5_m_rain_jacket_foldablehood",
    "Angle": "18.1",
    "X Scale": "41.61",
    "Y Scale": "41.61",
    "X Coordinate": "793.00",
    "Y Coordinate": "253.00",
  },
  "L012_5_m_rain_jacket_foldablehood": {
    "Jacket Angle": "L012_5_m_rain_jacket_foldablehood",
    "Angle": "1",
    "X Scale": "41.61",
    "Y Scale": "41.61",
    "X Coordinate": "650.00",
    "Y Coordinate": "1062.68",
  },
  "L009_6_m_rain_jacket_foldablehood": {
    "Jacket Angle": "L009_6_m_rain_jacket_foldablehood",
    "Angle": "19.28",
    "X Scale": "34.55",
    "Y Scale": "34.55",
    "X Coordinate": "770.29",
    "Y Coordinate": "445.98",
  },
  "L012_6_m_rain_jacket_foldablehood": {
    "Jacket Angle": "L012_6_m_rain_jacket_foldablehood",
    "Angle": "1",
    "X Scale": "34.55",
    "Y Scale": "34.55",
    "X Coordinate": "650.50",
    "Y Coordinate": "1113.75",
  },
  "L010_7_m_rain_jacket_foldablehood": {
    "Jacket Angle": "L010_7_m_rain_jacket_foldablehood",
    "Angle": "0",
    "X Scale": "140.45",
    "Y Scale": "140.45",
    "X Coordinate": "624.50",
    "Y Coordinate": "868.78",
  },
  "L009_8_m_rain_jacket_foldablehood": {
    "Jacket Angle": "L009_8_m_rain_jacket_foldablehood",
    "Angle": "20.95",
    "X Scale": "54.22",
    "Y Scale": "54.22",
    "X Coordinate": "871.47",
    "Y Coordinate": "692.62",
  },
  "L001_9_m_rain_jacket_foldablehood_bothpockets": {
    "Jacket Angle": "L001_9_m_rain_jacket_foldablehood_bothpockets",
    "Angle": "3",
    "X Scale": "72.00",
    "Y Scale": "72.00",
    "X Coordinate": "859.00",
    "Y Coordinate": "926.00",
  },
  "L001_9_m_rain_jacket_foldablehood_leftpocket": {
    "Jacket Angle": "L001_9_m_rain_jacket_foldablehood_leftpocket",
    "Angle": "3",
    "X Scale": "72.00",
    "Y Scale": "72.00",
    "X Coordinate": "859.00",
    "Y Coordinate": "926.00",
  },
  "L001_9_m_rain_jacket_foldablehood_nopocket": {
    "Jacket Angle": "L001_9_m_rain_jacket_foldablehood_nopocket",
    "Angle": "1",
    "X Scale": "72.00",
    "Y Scale": "72.00",
    "X Coordinate": "811.00",
    "Y Coordinate": "926.00",
  },
  "L001_9_m_rain_jacket_foldablehood_rightpocket": {
    "Jacket Angle": "L001_9_m_rain_jacket_foldablehood_rightpocket",
    "Angle": "1",
    "X Scale": "72.00",
    "Y Scale": "72.00",
    "X Coordinate": "811.00",
    "Y Coordinate": "926.00",
  },
  "L007_10_m_rain_jacket_foldablehood_pocket": {
    "Jacket Angle": "L007_10_m_rain_jacket_foldablehood_pocket",
    "Angle": "0",
    "X Scale": "73.23",
    "Y Scale": "73.23",
    "X Coordinate": "722.12",
    "Y Coordinate": "807.13",
  },
  "L007_10_m_rain_jacket_foldablehood_nopocket": {
    "Jacket Angle": "L007_10_m_rain_jacket_foldablehood_nopocket",
    "Angle": "0",
    "X Scale": "73.23",
    "Y Scale": "73.23",
    "X Coordinate": "722.12",
    "Y Coordinate": "807.13",
  },
  "L012_11_m_rain_jacket_foldablehood": {
    "Jacket Angle": "L012_11_m_rain_jacket_foldablehood",
    "Angle": "1",
    "X Scale": "73.15",
    "Y Scale": "73.15",
    "X Coordinate": "650.50",
    "Y Coordinate": "818.00",
  },
  "L001_1_w_rain_jacket_foldablehood_bothpockets": {
    "Jacket Angle": "L001_1_w_rain_jacket_foldablehood_bothpockets",
    "Angle": "2",
    "X Scale": "53.00",
    "Y Scale": "53.00",
    "X Coordinate": "806.27",
    "Y Coordinate": "425.00",
  },
  "L001_1_w_rain_jacket_foldablehood_leftpocket": {
    "Jacket Angle": "L001_1_w_rain_jacket_foldablehood_leftpocket",
    "Angle": "2",
    "X Scale": "53.00",
    "Y Scale": "53.00",
    "X Coordinate": "806.27",
    "Y Coordinate": "425.00",
  },
  "L001_1_w_rain_jacket_foldablehood_nopocket": {
    "Jacket Angle": "L001_1_w_rain_jacket_foldablehood_nopocket",
    "Angle": "1",
    "X Scale": "53.00",
    "Y Scale": "53.00",
    "X Coordinate": "764.27",
    "Y Coordinate": "425.00",
  },
  "L001_1_w_rain_jacket_foldablehood_rightpocket": {
    "Jacket Angle": "L001_1_w_rain_jacket_foldablehood_rightpocket",
    "Angle": "1",
    "X Scale": "53.00",
    "Y Scale": "53.00",
    "X Coordinate": "764.27",
    "Y Coordinate": "425.00",
  },
  "L001_2_w_rain_jacket_foldablehood_bothpockets": {
    "Jacket Angle": "L001_2_w_rain_jacket_foldablehood_bothpockets",
    "Angle": "2",
    "X Scale": "43.00",
    "Y Scale": "43.00",
    "X Coordinate": "775.00",
    "Y Coordinate": "600.67",
  },
  "L001_2_w_rain_jacket_foldablehood_leftpocket": {
    "Jacket Angle": "L001_2_w_rain_jacket_foldablehood_leftpocket",
    "Angle": "2",
    "X Scale": "43.00",
    "Y Scale": "43.00",
    "X Coordinate": "775.00",
    "Y Coordinate": "600.67",
  },
  "L001_2_w_rain_jacket_foldablehood_nopocket": {
    "Jacket Angle": "L001_2_w_rain_jacket_foldablehood_nopocket",
    "Angle": "1",
    "X Scale": "43.00",
    "Y Scale": "43.00",
    "X Coordinate": "745.00",
    "Y Coordinate": "600.67",
  },
  "L001_2_w_rain_jacket_foldablehood_rightpocket": {
    "Jacket Angle": "L001_2_w_rain_jacket_foldablehood_rightpocket",
    "Angle": "1",
    "X Scale": "43.00",
    "Y Scale": "43.00",
    "X Coordinate": "745.00",
    "Y Coordinate": "600.67",
  },
  "L007_3_w_rain_jacket_foldablehood_pocket": {
    "Jacket Angle": "L007_3_w_rain_jacket_foldablehood_pocket",
    "Angle": "-1",
    "X Scale": "29.68",
    "Y Scale": "29.68",
    "X Coordinate": "683.26",
    "Y Coordinate": "556.26",
  },
  "L007_3_w_rain_jacket_foldablehood_nopocket": {
    "Jacket Angle": "L007_3_w_rain_jacket_foldablehood_nopocket",
    "Angle": "-1",
    "X Scale": "29.68",
    "Y Scale": "29.68",
    "X Coordinate": "683.26",
    "Y Coordinate": "556.26",
  },
  "L009_5_w_rain_jacket_foldablehood": {
    "Jacket Angle": "L009_5_w_rain_jacket_foldablehood",
    "Angle": "12.66",
    "X Scale": "38.46",
    "Y Scale": "38.46",
    "X Coordinate": "785.00",
    "Y Coordinate": "233.66",
  },
  "L012_5_w_rain_jacket_foldablehood": {
    "Jacket Angle": "L012_5_w_rain_jacket_foldablehood",
    "Angle": "-0.5",
    "X Scale": "38.46",
    "Y Scale": "38.46",
    "X Coordinate": "650.00",
    "Y Coordinate": "1087.02",
  },
  "L009_6_w_rain_jacket_foldablehood": {
    "Jacket Angle": "L009_6_w_rain_jacket_foldablehood",
    "Angle": "14.02",
    "X Scale": "38.20",
    "Y Scale": "38.20",
    "X Coordinate": "747.11",
    "Y Coordinate": "451.13",
  },
  "L012_6_w_rain_jacket_foldablehood": {
    "Jacket Angle": "L012_6_w_rain_jacket_foldablehood",
    "Angle": "-0.2",
    "X Scale": "38.20",
    "Y Scale": "38.20",
    "X Coordinate": "650.00",
    "Y Coordinate": "1102.34",
  },
  "L010_7_w_rain_jacket_foldablehood": {
    "Jacket Angle": "L010_7_w_rain_jacket_foldablehood",
    "Angle": "0.5",
    "X Scale": "113.00",
    "Y Scale": "113.00",
    "X Coordinate": "645.50",
    "Y Coordinate": "536.50",
  },
  "L009_8_w_rain_jacket_foldablehood": {
    "Jacket Angle": "L009_8_w_rain_jacket_foldablehood",
    "Angle": "15.32",
    "X Scale": "66.73",
    "Y Scale": "56.11",
    "X Coordinate": "834.03",
    "Y Coordinate": "729.17",
  },
  "L001_9_w_rain_jacket_foldablehood_bothpockets": {
    "Jacket Angle": "L001_9_w_rain_jacket_foldablehood_bothpockets",
    "Angle": "3",
    "X Scale": "71.50",
    "Y Scale": "71.50",
    "X Coordinate": "861.00",
    "Y Coordinate": "942.00",
  },
  "L001_9_w_rain_jacket_foldablehood_leftpocket": {
    "Jacket Angle": "L001_9_w_rain_jacket_foldablehood_leftpocket",
    "Angle": "3",
    "X Scale": "71.50",
    "Y Scale": "71.50",
    "X Coordinate": "861.00",
    "Y Coordinate": "942.00",
  },
  "L001_9_w_rain_jacket_foldablehood_nopocket": {
    "Jacket Angle": "L001_9_w_rain_jacket_foldablehood_nopocket",
    "Angle": "1",
    "X Scale": "71.50",
    "Y Scale": "71.50",
    "X Coordinate": "808.00",
    "Y Coordinate": "942.00",
  },
  "L001_9_w_rain_jacket_foldablehood_rightpocket": {
    "Jacket Angle": "L001_9_w_rain_jacket_foldablehood_rightpocket",
    "Angle": "1",
    "X Scale": "71.50",
    "Y Scale": "71.50",
    "X Coordinate": "808.00",
    "Y Coordinate": "942.00",
  },
  "L007_10_w_rain_jacket_foldablehood_pocket": {
    "Jacket Angle": "L007_10_w_rain_jacket_foldablehood_pocket",
    "Angle": "-1",
    "X Scale": "80.92",
    "Y Scale": "80.92",
    "X Coordinate": "778.04",
    "Y Coordinate": "877.06",
  },
  "L007_10_w_rain_jacket_foldablehood_nopocket": {
    "Jacket Angle": "L007_10_w_rain_jacket_foldablehood_nopocket",
    "Angle": "-1",
    "X Scale": "80.92",
    "Y Scale": "80.92",
    "X Coordinate": "778.04",
    "Y Coordinate": "877.06",
  },
  "L012_11_w_rain_jacket_foldablehood": {
    "Jacket Angle": "L012_11_w_rain_jacket_foldablehood",
    "Angle": "0.5",
    "X Scale": "93.75",
    "Y Scale": "93.75",
    "X Coordinate": "664.50",
    "Y Coordinate": "709.00",
  },
  "L001_1_m_rain_jacket_standardhood_bothpockets": {
    "Jacket Angle": "L001_1_m_rain_jacket_standardhood_bothpockets",
    "Angle": "2",
    "X Scale": "52.00",
    "Y Scale": "52.00",
    "X Coordinate": "804.00",
    "Y Coordinate": "446.00",
  },
  "L001_1_m_rain_jacket_standardhood_leftpocket": {
    "Jacket Angle": "L001_1_m_rain_jacket_standardhood_leftpocket",
    "Angle": "2",
    "X Scale": "52.00",
    "Y Scale": "52.00",
    "X Coordinate": "804.00",
    "Y Coordinate": "446.00",
  },
  "L001_1_m_rain_jacket_standardhood_nopocket": {
    "Jacket Angle": "L001_1_m_rain_jacket_standardhood_nopocket",
    "Angle": "1",
    "X Scale": "52.00",
    "Y Scale": "52.00",
    "X Coordinate": "774.00",
    "Y Coordinate": "446.00",
  },
  "L001_1_m_rain_jacket_standardhood_rightpocket": {
    "Jacket Angle": "L001_1_m_rain_jacket_standardhood_rightpocket",
    "Angle": "1",
    "X Scale": "52.00",
    "Y Scale": "52.00",
    "X Coordinate": "774.00",
    "Y Coordinate": "446.00",
  },
  "L001_2_m_rain_jacket_standardhood_bothpockets": {
    "Jacket Angle": "L001_2_m_rain_jacket_standardhood_bothpockets",
    "Angle": "2",
    "X Scale": "42.00",
    "Y Scale": "42.00",
    "X Coordinate": "779.00",
    "Y Coordinate": "577.00",
  },
  "L001_2_m_rain_jacket_standardhood_leftpocket": {
    "Jacket Angle": "L001_2_m_rain_jacket_standardhood_leftpocket",
    "Angle": "2",
    "X Scale": "42.00",
    "Y Scale": "42.00",
    "X Coordinate": "779.00",
    "Y Coordinate": "577.00",
  },
  "L001_2_m_rain_jacket_standardhood_nopocket": {
    "Jacket Angle": "L001_2_m_rain_jacket_standardhood_nopocket",
    "Angle": "1",
    "X Scale": "42.00",
    "Y Scale": "42.00",
    "X Coordinate": "764.00",
    "Y Coordinate": "577.00",
  },
  "L001_2_m_rain_jacket_standardhood_rightpocket": {
    "Jacket Angle": "L001_2_m_rain_jacket_standardhood_rightpocket",
    "Angle": "1",
    "X Scale": "42.00",
    "Y Scale": "42.00",
    "X Coordinate": "764.00",
    "Y Coordinate": "577.00",
  },
  "L007_3_m_rain_jacket_standardhood_pocket": {
    "Jacket Angle": "L007_3_m_rain_jacket_standardhood_pocket",
    "Angle": "-1",
    "X Scale": "30.86",
    "Y Scale": "30.86",
    "X Coordinate": "703.68",
    "Y Coordinate": "556.00",
  },
  "L007_3_m_rain_jacket_standardhood_nopocket": {
    "Jacket Angle": "L007_3_m_rain_jacket_standardhood_nopocket",
    "Angle": "-1",
    "X Scale": "30.86",
    "Y Scale": "30.86",
    "X Coordinate": "703.68",
    "Y Coordinate": "556.00",
  },
  "L009_5_m_rain_jacket_standardhood": {
    "Jacket Angle": "L009_5_m_rain_jacket_standardhood",
    "Angle": "16",
    "X Scale": "39.68",
    "Y Scale": "39.68",
    "X Coordinate": "836.50",
    "Y Coordinate": "346.50",
  },
  "L012_5_m_rain_jacket_standardhood": {
    "Jacket Angle": "L012_5_m_rain_jacket_standardhood",
    "Angle": "1",
    "X Scale": "38.46",
    "Y Scale": "38.46",
    "X Coordinate": "650.00",
    "Y Coordinate": "1090.65",
  },
  "L009_6_m_rain_jacket_standardhood": {
    "Jacket Angle": "L009_6_m_rain_jacket_standardhood",
    "Angle": "16",
    "X Scale": "33.29",
    "Y Scale": "33.29",
    "X Coordinate": "788.12",
    "Y Coordinate": "459.47",
  },
  "L012_6_m_rain_jacket_standardhood": {
    "Jacket Angle": "L012_6_m_rain_jacket_standardhood",
    "Angle": "1",
    "X Scale": "33.29",
    "Y Scale": "33.29",
    "X Coordinate": "650.00",
    "Y Coordinate": "1124.57",
  },
  "L010_7_m_rain_jacket_standardhood": {
    "Jacket Angle": "L010_7_m_rain_jacket_standardhood",
    "Angle": "1",
    "X Scale": "142.79",
    "Y Scale": "142.79",
    "X Coordinate": "679.06",
    "Y Coordinate": "672.43",
  },
  "L009_8_m_rain_jacket_standardhood": {
    "Jacket Angle": "L009_8_m_rain_jacket_standardhood",
    "Angle": "16",
    "X Scale": "52.93",
    "Y Scale": "52.93",
    "X Coordinate": "854.22",
    "Y Coordinate": "686.17",
  },
  "L001_9_m_rain_jacket_standardhood_bothpockets": {
    "Jacket Angle": "L001_9_m_rain_jacket_standardhood_bothpockets",
    "Angle": "3",
    "X Scale": "72.00",
    "Y Scale": "72.00",
    "X Coordinate": "855.00",
    "Y Coordinate": "926.00",
  },
  "L001_9_m_rain_jacket_standardhood_leftpocket": {
    "Jacket Angle": "L001_9_m_rain_jacket_standardhood_leftpocket",
    "Angle": "3",
    "X Scale": "72.00",
    "Y Scale": "72.00",
    "X Coordinate": "855.00",
    "Y Coordinate": "926.00",
  },
  "L001_9_m_rain_jacket_standardhood_nopocket": {
    "Jacket Angle": "L001_9_m_rain_jacket_standardhood_nopocket",
    "Angle": "1",
    "X Scale": "72.00",
    "Y Scale": "72.00",
    "X Coordinate": "808.00",
    "Y Coordinate": "926.00",
  },
  "L001_9_m_rain_jacket_standardhood_rightpocket": {
    "Jacket Angle": "L001_9_m_rain_jacket_standardhood_rightpocket",
    "Angle": "1",
    "X Scale": "72.00",
    "Y Scale": "72.00",
    "X Coordinate": "808.00",
    "Y Coordinate": "926.00",
  },
  "L007_10_m_rain_jacket_standardhood_pocket": {
    "Jacket Angle": "L007_10_m_rain_jacket_standardhood_pocket",
    "Angle": "1",
    "X Scale": "69.24",
    "Y Scale": "69.24",
    "X Coordinate": "753.97",
    "Y Coordinate": "764.39",
  },
  "L007_10_m_rain_jacket_standardhood_nopocket": {
    "Jacket Angle": "L007_10_m_rain_jacket_standardhood_nopocket",
    "Angle": "1",
    "X Scale": "69.24",
    "Y Scale": "69.24",
    "X Coordinate": "753.97",
    "Y Coordinate": "764.39",
  },
  "L012_11_m_rain_jacket_standardhood": {
    "Jacket Angle": "L012_11_m_rain_jacket_standardhood",
    "Angle": "1",
    "X Scale": "74.21",
    "Y Scale": "74.21",
    "X Coordinate": "650.00",
    "Y Coordinate": "791.00",
  },
  "L001_1_w_rain_jacket_standardhood_bothpockets": {
    "Jacket Angle": "L001_1_w_rain_jacket_standardhood_bothpockets",
    "Angle": "2",
    "X Scale": "50.00",
    "Y Scale": "50.00",
    "X Coordinate": "817.41",
    "Y Coordinate": "445.24",
  },
  "L001_1_w_rain_jacket_standardhood_leftpocket": {
    "Jacket Angle": "L001_1_w_rain_jacket_standardhood_leftpocket",
    "Angle": "2",
    "X Scale": "50.00",
    "Y Scale": "50.00",
    "X Coordinate": "817.41",
    "Y Coordinate": "445.24",
  },
  "L001_1_w_rain_jacket_standardhood_nopocket": {
    "Jacket Angle": "L001_1_w_rain_jacket_standardhood_nopocket",
    "Angle": "2",
    "X Scale": "50.00",
    "Y Scale": "50.00",
    "X Coordinate": "793.32",
    "Y Coordinate": "445.24",
  },
  "L001_1_w_rain_jacket_standardhood_rightpocket": {
    "Jacket Angle": "L001_1_w_rain_jacket_standardhood_rightpocket",
    "Angle": "2",
    "X Scale": "50.00",
    "Y Scale": "50.00",
    "X Coordinate": "793.32",
    "Y Coordinate": "445.24",
  },
  "L001_2_w_rain_jacket_standardhood_bothpockets": {
    "Jacket Angle": "L001_2_w_rain_jacket_standardhood_bothpockets",
    "Angle": "2",
    "X Scale": "43.00",
    "Y Scale": "43.00",
    "X Coordinate": "773.30",
    "Y Coordinate": "586.40",
  },
  "L001_2_w_rain_jacket_standardhood_leftpocket": {
    "Jacket Angle": "L001_2_w_rain_jacket_standardhood_leftpocket",
    "Angle": "2",
    "X Scale": "43.00",
    "Y Scale": "43.00",
    "X Coordinate": "773.30",
    "Y Coordinate": "586.40",
  },
  "L001_2_w_rain_jacket_standardhood_nopocket": {
    "Jacket Angle": "L001_2_w_rain_jacket_standardhood_nopocket",
    "Angle": "2",
    "X Scale": "43.00",
    "Y Scale": "43.00",
    "X Coordinate": "747.30",
    "Y Coordinate": "586.40",
  },
  "L001_2_w_rain_jacket_standardhood_rightpocket": {
    "Jacket Angle": "L001_2_w_rain_jacket_standardhood_rightpocket",
    "Angle": "2",
    "X Scale": "43.00",
    "Y Scale": "43.00",
    "X Coordinate": "747.30",
    "Y Coordinate": "586.40",
  },
  "L007_3_w_rain_jacket_standardhood_pocket": {
    "Jacket Angle": "L007_3_w_rain_jacket_standardhood_pocket",
    "Angle": "1",
    "X Scale": "33.06",
    "Y Scale": "33.06",
    "X Coordinate": "695.18",
    "Y Coordinate": "552.02",
  },
  "L007_3_w_rain_jacket_standardhood_nopocket": {
    "Jacket Angle": "L007_3_w_rain_jacket_standardhood_nopocket",
    "Angle": "1",
    "X Scale": "33.06",
    "Y Scale": "33.06",
    "X Coordinate": "695.18",
    "Y Coordinate": "552.02",
  },
  "L009_5_w_rain_jacket_standardhood": {
    "Jacket Angle": "L009_5_w_rain_jacket_standardhood",
    "Angle": "12.97",
    "X Scale": "43.69",
    "Y Scale": "43.69",
    "X Coordinate": "785.00",
    "Y Coordinate": "235.04",
  },
  "L012_5_w_rain_jacket_standardhood": {
    "Jacket Angle": "L012_5_w_rain_jacket_standardhood",
    "Angle": "1",
    "X Scale": "43.69",
    "Y Scale": "43.69",
    "X Coordinate": "650.00",
    "Y Coordinate": "1052.89",
  },
  "L009_6_w_rain_jacket_standardhood": {
    "Jacket Angle": "L009_6_w_rain_jacket_standardhood",
    "Angle": "10.27",
    "X Scale": "39.76",
    "Y Scale": "39.76",
    "X Coordinate": "758.09",
    "Y Coordinate": "465.59",
  },
  "L012_6_w_rain_jacket_standardhood": {
    "Jacket Angle": "L012_6_w_rain_jacket_standardhood",
    "Angle": "1",
    "X Scale": "37.15",
    "Y Scale": "37.15",
    "X Coordinate": "650.00",
    "Y Coordinate": "1083.43",
  },
  "L010_7_w_rain_jacket_standardhood": {
    "Jacket Angle": "L010_7_w_rain_jacket_standardhood",
    "Angle": "-0.5",
    "X Scale": "141.03",
    "Y Scale": "141.03",
    "X Coordinate": "674.75",
    "Y Coordinate": "742.47",
  },
  "L009_8_w_rain_jacket_standardhood": {
    "Jacket Angle": "L009_8_w_rain_jacket_standardhood",
    "Angle": "13.25",
    "X Scale": "72.54",
    "Y Scale": "72.54",
    "X Coordinate": "853.21",
    "Y Coordinate": "782.99",
  },
  "L001_9_w_rain_jacket_standardhood_bothpockets": {
    "Jacket Angle": "L001_9_w_rain_jacket_standardhood_bothpockets",
    "Angle": "2",
    "X Scale": "71.24",
    "Y Scale": "71.24",
    "X Coordinate": "870.00",
    "Y Coordinate": "927.00",
  },
  "L001_9_w_rain_jacket_standardhood_leftpocket": {
    "Jacket Angle": "L001_9_w_rain_jacket_standardhood_leftpocket",
    "Angle": "2",
    "X Scale": "71.24",
    "Y Scale": "71.24",
    "X Coordinate": "870.00",
    "Y Coordinate": "927.00",
  },
  "L001_9_w_rain_jacket_standardhood_nopocket": {
    "Jacket Angle": "L001_9_w_rain_jacket_standardhood_nopocket",
    "Angle": "0",
    "X Scale": "71.24",
    "Y Scale": "71.24",
    "X Coordinate": "817.00",
    "Y Coordinate": "927.00",
  },
  "L001_9_w_rain_jacket_standardhood_rightpocket": {
    "Jacket Angle": "L001_9_w_rain_jacket_standardhood_rightpocket",
    "Angle": "0",
    "X Scale": "71.24",
    "Y Scale": "71.24",
    "X Coordinate": "817.00",
    "Y Coordinate": "927.00",
  },
  "L007_10_w_rain_jacket_standardhood_pocket": {
    "Jacket Angle": "L007_10_w_rain_jacket_standardhood_pocket",
    "Angle": "-1",
    "X Scale": "81.54",
    "Y Scale": "81.54",
    "X Coordinate": "734.10",
    "Y Coordinate": "840.19",
  },
  "L007_10_w_rain_jacket_standardhood_nopocket": {
    "Jacket Angle": "L007_10_w_rain_jacket_standardhood_nopocket",
    "Angle": "-1",
    "X Scale": "81.54",
    "Y Scale": "81.54",
    "X Coordinate": "734.10",
    "Y Coordinate": "840.19",
  },
  "L012_11_w_rain_jacket_standardhood": {
    "Jacket Angle": "L012_11_w_rain_jacket_standardhood",
    "Angle": "0",
    "X Scale": "94.89",
    "Y Scale": "94.89",
    "X Coordinate": "650.00",
    "Y Coordinate": "672.00",
  },
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { HideMobile } from 'components/ui/Utils';
import { getSelectedLogoUrl } from 'store/selectors/subOrder';

import DesignOption from './DesignOption';
import { DesignOptionContainer } from './DesignOptionContainer';
import Box from 'components/ui/Layout/Box/Box';
import Icon from 'components/ui/Icons/Icon';
import { OptionGrid, OptionsTitle, Chevron } from './FeatureDesignStyles';
import { Breadcrumb } from 'components/ui/Buttons/Buttons';
import LogoDesignOptions from './Logo/LogoDesignOptions';
import { VisibleColorOrder, StyleNames } from '../../../utils/constants/lookups';


const FeaturePanel = styled(Box)`
  overflow: auto;
`;

const OutOfInventoryContainer = styled.div`
  align-items: center;
  display: flex;
  padding-left: 0.4rem;
  padding-top: 0.2rem;

  i {
    margin-right: 0.4rem;
  }

  > * {

    color: ${props => props.theme.color_grey_dark};
    font-size: 1.4rem;
    font-weight: ${props => props.theme.font_weight_semi_bold};
    line-height: 1.71;
  }
`;

class FeatureDesignPanel extends Component {
  static propTypes = {
    feature: PropTypes.object,
    onOptionSelected: PropTypes.func,
    handleDrawerBackClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
    };
  }

  isLogo(featureId) {
    return featureId.startsWith('L');
  }

  isColor(featureId) {
    const { styleClassifications } = this.props.order.config;

    return !!{...styleClassifications.colors, ...styleClassifications.zippers}[featureId];
  }

  handleDrawerBackClick() {
    this.props.handleDrawerBackClick();
  }

  renderOption(option, featureId, key) {
    const { order: { config } } = this.props;

    if (this.isLogo(featureId)) {
      if (key > 1) return;

      if (!option.name.trim()) {
        option.name = 'Add Logo';
      }
    }
    //TODO: here we are going to need to change the lookup to look by name perhaps?
    const selected = option.option_no === Object.values(this.props.order.order.cartSubs[0].cartSubDetail).find(feature => feature.itemNo === featureId).optionNo;
    // Is it a color option?
    const isInline = !Object.values(config.imageNameToStyleOption).includes(featureId);

    const color = option.color_id ? `#${config.colorConfiguration[`${option.color_id}`].buttonColor}` : null;

    return (
      <DesignOptionContainer
        key={key}
        selected={selected}
        inline={isInline}
        color={color}
        onOptionSelected={() => this.props.onOptionSelected(this.props.feature, option.option_no)}
        outOfInventory={option.outOfInventory}
      >
        {!isInline ?
          <DesignOption
            color={color}
            label={option.name}
          />
          :
          <DesignOption
            label={option.name}
            inline={isInline}
            addPrice={option.add_price}
          />
        }
      </DesignOptionContainer>
    );
  }

  renderStandardOptions(options, id) {

    const outOfInventory = options.find(option => option.outOfInventory === true);

    return (
      <FeaturePanel classes="left2Md">
        <HideMobile>
          <Box classes="top3Md">
            <OptionsTitle>
              {StyleNames[id]}
            </OptionsTitle>
          </Box>
          {outOfInventory &&
            <OutOfInventoryContainer>
              <Icon name="fabricOutOfInventory" size={16} />
              <span>Temporarily out of stock</span>
            </OutOfInventoryContainer>
          }
        </HideMobile>
        <OptionGrid>
          {options.map((option, idx) => {
            return this.renderOption(option, id, idx);
          })}
        </OptionGrid>
      </FeaturePanel>
    );
  }

  renderLogoOptions(options, id) {
    const { order } = this.props;

    const selectedOption = getSelectedLogoUrl(order, id);

    return (
      <LogoDesignOptions
        selectedOption={selectedOption}
        options={options}
        id={id}></LogoDesignOptions>
    );
  }

  render() {
    const {feature} = this.props;

    if ( !feature || !this.props.colors ) return null;
    const id = feature.item_no;

    let options = feature.options;

    if (this.isColor(id)) {
      options.sort((a, b) => {
        return VisibleColorOrder.indexOf(`${a.color_id}`) - VisibleColorOrder.indexOf(`${b.color_id}`);
      });
    }

    return (
      <>
        <HideMobile>
          <Box classes="top2 left2">
            <Breadcrumb onClick={() => this.handleDrawerBackClick()}>
              <Chevron size={24} name="chevron"/>
              Back
            </Breadcrumb>
          </Box>
        </HideMobile>
        {this.isLogo(id) ? this.renderLogoOptions(options, id): this.renderStandardOptions(options, id)}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.selectedOrder.order,
  colors: state.colors.colors,
});

export default connect(mapStateToProps, null)(FeatureDesignPanel);

import React, { Component } from 'react';
import styled from 'styled-components/macro';

import { ShowMobile } from 'components/ui/Utils';
import Icon from 'components/ui/Icons/Icon';
import { PropTypes } from 'prop-types';

const AlertContainer = styled(ShowMobile)`
  align-items: center;
  background-color: ${props => props.theme.color_white};
  display: flex;
  height: ${props => props.show ? '3.2rem' : 0};
  justify-content: center;
  overflow: hidden;
  transition: all .4s ${props => props.theme.ease_out_quad};
  width: 100%;

  > i {
    margin-right: 0.4rem;
  }

  > * {

    color: ${props => props.theme.color_grey_dark};
    font-size: 1.4rem;
    font-weight: ${props => props.theme.font_weight_semi_bold};
    line-height: 1.71;
  }
`;

const Close = styled.button`
  height: 3.2rem;
  margin-top: 0.4rem;
  position: absolute;
  right: 1.1rem;
`;

class Alert extends Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      show: !sessionStorage.getItem(`${props.name}_alert`),
    };
  }

  close(e) {
    e.preventDefault();

    sessionStorage.setItem(`${this.props.name}_alert`, true);

    this.setState({
      show: false,
    });
  }

  render() {
    return (
      <AlertContainer show={this.state.show}>
        <Icon name="fabricOutOfInventory" size={16} />
        <span>Temporarily out of stock</span>
        <Close onClick={e => this.close(e)}>
          <Icon size={20} name="close" />
        </Close>
      </AlertContainer>
    );
  }
}

export default Alert;

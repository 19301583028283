import React from 'react';
import styled from 'styled-components/macro';
import media from 'utils/media';
import { rhythm } from 'utils/helpers';

import Box from 'components/ui/Layout/Box/Box';
import Typography from 'components/ui/Typography/Typography';
import { GetString, ProductDescriptions } from 'utils/constants/lookups';

const CardWrapper = styled.div`
  & + & {
    padding-top: ${rhythm(.5)};
  }

  ${media.md`
    flex: 1;
    flex-basis: 50%;
    padding-top: 0;
    padding: ${rhythm(2)} ${rhythm(2)};

    & + & {
      padding: ${rhythm(2)} ${rhythm(2)};
    }
  `}
`;

const Img = styled.img`
  height: 15rem;
  width: auto;

  ${media.md`
    height: auto;
    width: 100%;
  `}
`;

const BasePrice = styled.span`
  padding-right: ${rhythm(.5)};

  ${media.md`
    padding-right: ${rhythm(1)};
  `}
`;

const Card = styled.button`
  background-color: ${props => props.theme.color_white};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: ${rhythm(3)} ${rhythm(2)};
  text-align: left;

  &:focus {
    outline: auto
  }

  ${media.md`
    flex-direction: column;
    height: 100%;
    padding: ${rhythm(3)};
  `}
`;

const CardContent = styled.div`
  flex-grow: 1;
  padding-left: ${rhythm(3)};

  ${media.md`
    padding-left: 0;
    padding-top: ${rhythm(4)};
  `}
`;

export default function NewOrderCard(props) {
  return (
    <CardWrapper>
      <Card
        onClick={(e) => props.handleNewOrderClick(e, props.product)}
        role="button" tabIndex="0">
        <Img src={props.product.img_path} alt={props.product.name} />
        <CardContent>
          <Typography variant="h3">
            {props.product.name}
          </Typography>
          <Box classes="top1Md">
            <Typography variant="meta2" color="color_grey_dark_1">
              {GetString(props.product.style_no, ProductDescriptions)}
            </Typography>
          </Box>
          <Box classes="top3">
            <Typography variant="h2" color="color_grey_dark_1">
              <BasePrice>
                ${props.product.price}
              </BasePrice>
              <Typography variant="metaTitle">
                base price
              </Typography>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </CardWrapper>
  );
}
